const sortBy = (key: string) => {
  return (a: any, b: any) => {
    if (a[key] > b[key]) return 1
    if (a[key] < b[key]) return -1
    return 0
  }
}

const listColors = (idx: number) => {
  const colors = [
    "#39add1", // light blue
    "#3079ab", // dark blue
    "#7d669e", // purple
    "#51b46d", // green
    "#e15258", // red
    "#637a91", // dark gray
    "#b7c0c7"  // light gray
  ];
  return colors[idx];
};

const getColorByKey = (key: string, idx?: number) => {
  let color = '#31AAEB';
  switch(key) {
  case "Promotores":
  case "Positivo":
  case "positive":
  case "Positivos":
  case "Fácil":
  case "promoter":
  case "easy":
  case "Si":
  case "Sí":
  case "completed":
  case "WhatsApp":
  case "Teléfono":
  case "1":
      color = "#30b131";
      break;
  case "Alguien lo hizo por mí":
  case "Negativo":
  case "Negativos":
  case "negative":
  case "Detractores":
  case "Difícil":
  case "detractor":
  case "difficult":
  case "No":
  case "started":
  case "Descartados":
  case "discardedRecords":
  case "2":
      color = "#E84147";
      break;
  case "Neutro":
  case "Neutros":
  case "neutral":
  case "Tal vez":
  case "3":
      color = "#e6e6e6";
      break;
  case "Mixed":
  case "Mixtos":
  case "Mixto":
  case "leave":
  case "4":
      color = "#31AAEB";
      break;
  case "Dispositivo móvil":
    color = "#E9720B";
    break;
  case "Página web":
    color = "#39add1";
    break;
  case "Aplicación móvil":
    color = "#3079ab";
    break;
  case "Otro":
    color = "#af9eab";
    break;
  case "Mostrador":
    color = "#7d669e";
    break;
  default:
      color = 'none';
  }
  if (color === 'none') color = listColors(idx ? idx : 0)
  return color;
};

export {
  sortBy,
  getColorByKey
}