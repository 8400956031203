import { useCallback } from 'react';
import { reports } from '../../services';
import { KpiSummaryReport, KpiSummaryReportQuery } from '../../services/reports';
import { useBaseKpi, BaseKpiConfig } from './base/kpi-hook.base';
import { DataProviderType } from '../../services/views';

const useKpiSummary = (config: BaseKpiConfig) => {
  const {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  } = useBaseKpi<KpiSummaryReport, KpiSummaryReportQuery>(config);

  const fetchData = async (query: KpiSummaryReportQuery): Promise<KpiSummaryReport> => {
    return config.dataProvider === DataProviderType.INGESTION_RECORDS 
      ? await reports.getImportsReport(query)
      : await reports.getKpiSummaryReport(query);
  };

  const execRequest = useCallback(async () => {
    try {
      setAnalyticsResult(undefined);
      setAnalyticsError(undefined);
      setLoading(true);

      const validationError = validateRequest();
      if (validationError) {
        return setAnalyticsError(validationError);
      }

      const query = buildBaseQuery();
      const response = await fetchData(query as KpiSummaryReportQuery);
      
      setAnalyticsResult(response);
    } catch (err) {
      setAnalyticsError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [studies.selected, dateRange, filters]);

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ] as const;
};

export default useKpiSummary;