import { reports } from ".";
import { useAppSelector } from "../store/hooks";
import { selectDateRange, selectFilters } from "../store/slices/filters";
import { selectSlice } from "../store/slices/studies";
import { DataClickAction, DataClickConfig } from "./views";

function saveFileAs(blob: BlobPart, suggestedName: string) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${suggestedName}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  return;
}

export const useHandleDataClick = () => {
    const studies = useAppSelector(selectSlice);
    const filters = useAppSelector(selectFilters);
    const dateRange = useAppSelector(selectDateRange);

    const handleDataClick = async (dataClickConfig: DataClickConfig, dataClickValue: string, query: any) => {
        if(!dataClickConfig || !dataClickValue || !query) return;

        const newQuery: any = {
            field: query.field,
            dateRangeField: query.dateRangeField,
            calc: {
                type: dataClickConfig.action
            },
            baseFilters: [{
                field: dataClickConfig.field,
                values: [dataClickValue]
            }]
        };

        switch (dataClickConfig.action) {
            case DataClickAction.EXPORT_RECORDS:
                if(studies.selected?._id){
                    const response = await reports.getDownloadImportsCSV({
                        studyId: studies.selected?._id, 
                        dateRange, 
                        filters, 
                        query: newQuery
                    });
                    if (response) {
                        saveFileAs(
                            response,
                            `${dataClickValue}_${dateRange.start?.format(
                                "DD-MM-YY"
                            )}_${dateRange.end?.format("DD-MM-YY")}.csv`
                        );
                    }
                }
                break;
            default:
                break;
        }
    };

    return handleDataClick;
};