import { useCallback } from 'react';
import { BaseKpiConfig, useBaseKpi } from './base/kpi-hook.base';
import { reports } from '../../services';
import { SummaryImportedRecords, SummaryImportedRecordQuery } from '../../services/reports';
import { Filter } from '../../store/slices/filters';

type SummaryImportedRecordsConfig = Omit<BaseKpiConfig, 'field' | 'calc' | 'dataProvider'> & {
  baseFilters?: Filter[];
}

const useSummaryImportedRecords = (config: SummaryImportedRecordsConfig) => {
  const {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  } = useBaseKpi<SummaryImportedRecords, SummaryImportedRecordQuery>(config);

  const buildSummaryQuery = (): SummaryImportedRecordQuery => {
    const baseQuery = buildBaseQuery();
    return baseQuery as SummaryImportedRecordQuery;
  };

  const execRequest = useCallback(async () => {
    try {
      setAnalyticsResult(undefined);
      setAnalyticsError(undefined);
      setLoading(true);

      const validationError = validateRequest();
      if (validationError) {
        return setAnalyticsError(validationError);
      }

      const query = buildSummaryQuery();
      const response = await reports.getSummaryImportedRecords(query);
      
      setAnalyticsResult(response);
    } catch (err) {
      setAnalyticsError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [studies.selected, dateRange, filters]);

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ] as const;
};

export default useSummaryImportedRecords;