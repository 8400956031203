import { useCallback } from 'react';
import { reports } from '../../services';
import { CSATAttributesReport, CSATAttributesReportQuery } from '../../services/reports';
import { useBaseKpi, BaseKpiConfig } from './base/kpi-hook.base';
import { messages } from '../../utils';

interface CSATAttributesConfig extends BaseKpiConfig {
  attributes: string[];
  variable: {
    name: string;
    calc: any;
  };
}

const useCSATAttributes = (config: CSATAttributesConfig) => {
  const {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  } = useBaseKpi<CSATAttributesReport, CSATAttributesReportQuery>(config);

  const fetchData = async (query: CSATAttributesReportQuery): Promise<CSATAttributesReport> => {
    const response = await reports.getCSATAttributesReport(query);
    if (response.n < 1) {
      throw new Error(messages.NO_DATA_FOR_QUERY);
    }
    return response;
  };

  const buildCSATQuery = (): CSATAttributesReportQuery => {
    const baseQuery = buildBaseQuery();
    return {
      ...baseQuery,
      attributes: config.attributes,
      variable: config.variable
    } as CSATAttributesReportQuery;
  };

  const execRequest = useCallback(async () => {
    try {
      setAnalyticsResult(undefined);
      setAnalyticsError(undefined);
      setLoading(true);

      const validationError = validateRequest();
      if (validationError) {
        return setAnalyticsError(validationError);
      }

      const query = buildCSATQuery();
      const response = await fetchData(query as CSATAttributesReportQuery);
      
      setAnalyticsResult(response);
    } catch (err) {
      setAnalyticsError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [studies.selected, dateRange, filters]);

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ] as const;
};

export default useCSATAttributes;