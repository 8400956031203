import React, { useEffect, useState } from 'react'
import { CalcParams, CalcType } from '../../../services/reports'
import { LocalizedText, TimeUnit } from '../../../services/filters'
import moment from 'moment'
import './kpi-summary.w.css'
import { getColorByKey, roundNumber } from '../../../utils'
import useKpiTrend from '../../../common/hooks/use-kpi-trend'
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'
import { Dropdown } from '../../../common/components/dropdown'
import { CardMessage } from '../../../common/components/widget-card-message.w'
import { DataProviderType, ReportConfig, ReportTypes } from '../../../services/views'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Tooltip as TooltipTable } from '@material-ui/core'
import BaseTable from '../../../common/widgets/table.w'
moment.locale('es', {
  months: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
})

type TimeUnitOption = {
  distinctId: TimeUnit,
  display: LocalizedText,
}

export type KpiTrendConfig = ReportConfig<
  {
    dateRangeField: string,
    field: string,
    calc: CalcParams,
    timeUnit: TimeUnit,
    resultId: string
    dataProvider?: ReportTypes & DataProviderType
  },
  {
    availableTimeUnits: Array<TimeUnitOption>,
    showBar?: boolean,
    showLine?: boolean,
    resultDisplay: LocalizedText,
    maxDomain?: number
    showTable?: boolean;
  }
>

const xTickFormatter = (tick: string, dataLen: number, group = "") => {
  if (dataLen > 5) {
      return '';
  }
  if (group === TimeUnit.week) {
      const values = tick.split(" ").slice(0, 2);
      return values.join(" ");
  }
  return tick;
};

const baseColProps: Partial<GridColDef> = {
  flex: 0.5,
  headerAlign: 'right',
  align: 'right',
  type: 'number',
  sortable: true,
}

const KpiTrend = ({
  title = { default: '', es: '' },
  dataProvider,
  query,
  options: {
    availableTimeUnits,
    showBar,
    showLine,
    resultDisplay: { es: resultLabel },
    maxDomain,
    showTable
  },
}: KpiTrendConfig) => {
  const [timeUnit, setTimeUnit] = useState(query.timeUnit);
  const [loadReport, loading, error, kpiTrend] = useKpiTrend({ ...query, timeUnit, dataProvider });

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  const cols: GridColDef[] = [
    {
      field: 'dateRangeDisplay',
      type: 'string',
      headerName: 'Fechas',
      disableColumnMenu: true,
      flex: 0.6,
  
      renderCell: ({ value }: GridCellParams) => (
        <TooltipTable title={value || ''}>
          <span>{value}</span>
        </TooltipTable>
      ),
      sortable: true,
    },
  ];

  if (loading) return <CardMessage />
  if (error || !kpiTrend) return (
    <CardMessage message={{ default: 'Error on report', es: `Error al cargar reporte:\n${error?.message}` }} />
  )
  const typeQuestion = query.calc.type === CalcType.QUESTION;
  if (typeQuestion) {
    kpiTrend.periods.forEach((period) => {
      period.detail.sort((p1, p2) => (p1.percentage < p2.percentage) ? 1 : (p1.percentage > p2.percentage) ? -1 : 0);
    });
  }
  const chartData:any = kpiTrend.periods.map(p => ({
    ...p,
    dateRangeDisplay: moment(p.dateRange?.start).locale('es').format(timeUnit === TimeUnit.month ? 'MMMM' : 'DD MMMM'),
    ...p.detail.reduce((a, d) => (Object.assign(a, {
      [d.distinctId]: d.percentage
    })), {})
  }))
  const detailDisplay: Record<string, string> = chartData[0].detail.reduce((map:any, segment:any) => Object.assign(map, {
    [segment.distinctId]: segment.display.display.es
  }), { result: resultLabel })
  const showBars = showBar !== undefined ? showBar : true;
  const showLines = showLine !== undefined ? showLine : true;
  const max = maxDomain !== undefined ? maxDomain : 100;
  const invitations = query.calc.options?.invitations;

  const tableData:any = showTable ? kpiTrend.periods.map(p => {
    let res = {};
    p.detail.forEach((data:any) => {
      Object.assign(res,{[`${data.display.display.es} #`]: data.n, [`${data.display.display.es}`]: query.calc.type === CalcType.AVG ? data.percentage : `${data.percentage}%`})
      cols.push({
        ...baseColProps,
        field:`${data.display.display.es} #`,
        headerName: `${data.display.display.es} #`,
      })
      cols.push({
        ...baseColProps,
        field: `${data.display.display.es}`,
        headerName: `${data.display.display.es}`
      })
    })
    if (invitations) {
      cols.push({
        ...baseColProps,
        field: 'answers',
        headerName: 'Respuestas'
      })
      cols.push({
        ...baseColProps,
        field: 'tasa',
        headerName: 'Tasa de respuestas'
      })
      cols.push({
        ...baseColProps,
        field: 'invitations',
        headerName: 'Invitaciones'
      })
      if (p.invitations) Object.assign(res, {invitations: p.invitations, answers: p.n, tasa: `${roundNumber((p.n/p.invitations)*100)}%`})
    }
    return ({
      ...res,
      dateRangeDisplay: moment(p.dateRange?.start).locale('es').format(timeUnit === TimeUnit.month ? 'MMMM' : 'DD MMMM')
    })
  }): [];

  return (
    <div className="chart-widget-card-responsive">
      <div className="chart-widget-card-header">
        <div>
          <b>{title.es}</b>
        </div>
        <Dropdown
          options={availableTimeUnits}
          selectedValue={timeUnit}
          onChange={(o) => setTimeUnit(o.distinctId as TimeUnit)}
        />
      </div>
      <ComposedChart
        width={540}
        height={320}
        data={chartData}
      >
        <XAxis dataKey="dateRangeDisplay"
          tickFormatter={(t) => xTickFormatter(t, chartData.length, query.timeUnit)}
        />
        <YAxis
          yAxisId="left"
          dataKey="percentage"
          domain={[(dataMin: number) => dataMin >= 0 ? 0 : Math.round(dataMin / 100) * 100 - 20, max]}
          tickCount={11}
          padding={{top: 20, bottom:20}}
        />
        <YAxis
          yAxisId="right"
          orientation="right" 
          dataKey={invitations ? "invitations": "result"}
          domain={[(dataMin: number) => dataMin >= 0 ? 0 : (Math.round(dataMin / 100) * 100) - 20, max]}
          tickCount={11}
          padding={{top: 20, bottom:20}}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip
          offset={40}
          separator=": "
          formatter={(v: any, name: any, { dataKey }: any) => [['result'].includes(dataKey) || dataKey === 'invitations' ? `${v}` : `${v}%`, detailDisplay[dataKey] ? `${detailDisplay[dataKey]}` : "Invitaciones"]}
        />
        <Legend  />
        {showBars && chartData[0]?.detail?.map((segment:any, idx:number) => {
          const color = getColorByKey(typeQuestion ? segment.display.display.es : segment.distinctId, idx);
          return (
            <Bar
              yAxisId="left"
              key={segment.distinctId}
              name={segment.display.display.es}
              stackId="a"
              dataKey={segment.distinctId}
              fill={color}
              opacity={0.75}
              maxBarSize={48}
            >
            </Bar>
          );
        })}
        {showLines && <Line
          dataKey="result"
          yAxisId="right"
          name={resultLabel}
          type="monotone"
          stroke="#070707"
        >
          <LabelList offset={12} position={'top'} fontSize={chartData.length > 14 ?  10 : 14} />
        </Line>}
        {invitations && <Line
          dataKey="invitations"
          yAxisId="right"
          name="Invitaciones"
          type="monotone"
          stroke="#DF7401"
        >
          <LabelList offset={12} position={'top'} fontSize={chartData.length > 14 ?  10 : 14} />
        </Line>}
      </ComposedChart>
      {showTable &&
        <div style={{width: '96%', padding: '12px'}}>
          <BaseTable
          rowIdKey="dateRangeDisplay"
          rows={tableData}
          columns={cols}
          loading={loading}
          onReload={loadReport}
          rowHeight={32}
          tableTitle={'Datos'}
          disableSelectionOnClick
          disableDensitySelector
          disableColumnSelector
          disableColumnFilter
          autoHeight
          pageSize={10}
          localeText={{
            toolbarFilters: '',
            toolbarExport: '',
            toolbarColumns: 'Columnas',
            noRowsLabel: 'No se encontraron registros.',
            toolbarExportCSV: 'Descargar CSV',
            toolbarExportLabel: 'Exportar',
            MuiTablePagination: {
              labelRowsPerPage: 'Datos por página:',
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
            }
          }}
        />
        </div>
        }
    </div>
  )
}

export {
  KpiTrend
}