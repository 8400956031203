import { storage } from '..';
import { FilterDateRange, FilterOptions } from '../../store/slices/filters';
import { ServerResponse, constants } from '../../utils';
import { PaginationOptions } from '../comments';
import {
  CSATAttributesReport,
  CSATAttributesReportQuery,
  GetDataListOptions,
  GetDataListRequest,
  KpiSummaryReport,
  KpiSummaryReportQuery,
  KpiTrendReport,
  KpiTrendReportQuery,
  MultimetricTrendReport,
  MultimetricTrendReportQuery,
  MultivarTrendReport,
  MultivarTrendReportQuery, NpsBreakdownReport, NpsBreakdownReportQuery,
  ReportResponse,
  SegmentedReport,
  SegmentedReportQuery,
  SegmentedReportResponse,
  SummaryImportedRecords
} from './reports.models';

interface ImportConfig {
  studyId: string;
  dateRange: FilterDateRange;
  filters: FilterOptions[];
  query: any;
}


async function getSegmentedReport (query: SegmentedReportQuery): Promise<SegmentedReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/segmented-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: SegmentedReportResponse = await fetchResponse.json();
  const viewConfig = response.report;
  if (!viewConfig) throw new Error('No view config found')

  return viewConfig;
}

async function getImportsReport (query: KpiSummaryReportQuery): Promise<KpiSummaryReport> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };
  let URL = `${constants.API_URL}/analytics/imports-report`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiSummaryReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')
  return report;
}

async function getDownloadImportsCSV (config: ImportConfig): Promise<any> {
  const {studyId, dateRange, filters, query}= config;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);

  if (!studyId) {
    throw new Error(('No hay estudio seleccionado'));
  }
  if (!dateRange.start || !dateRange.end ) {
    throw new Error(('No hay periodo de tiempo seleccionado'));
  }
  const newQuery: KpiSummaryReportQuery = {
    studyId,
    dateRange: {
      field: query.dateRangeField,
      start: dateRange.start.startOf('day').toISOString(),
      end: dateRange.end.endOf('day').toISOString(),
    },
    field: query.field,
    calc:query.calc
  }
  if (query.baseFilters) {
    newQuery.filters = query.baseFilters
  }
  if (filters.length) {
    const standarizedFilters = filters.map((f:any )=> ({
      field: f.field,
      values: f.values.map((v:any )=> v.value),
    }));
    if (query.baseFilters) {
      const filtersToOverride = standarizedFilters.map(bf => bf.field)
      const newBaseFilters = query.baseFilters.filter((f:any) => !filtersToOverride.includes(f.field))
      newQuery.filters = [...newBaseFilters, ...standarizedFilters]
    } else {
      newQuery.filters = standarizedFilters
    }
  }

  let URL = `${constants.API_URL}/analytics/imports-report`;

  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newQuery)
  };

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse;
  const report = response.ok ? response.blob(): response.json();
  if (!report) throw new Error('No report found')

  return report;
}

async function getKpiSummaryReport (query: KpiSummaryReportQuery): Promise<KpiSummaryReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/general-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiSummaryReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}


async function getKpiTrendReport (query: KpiTrendReportQuery): Promise<KpiTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getMultimetricTrendReport (query: MultimetricTrendReportQuery): Promise<MultimetricTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/multimetric-trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<MultimetricTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getDownloadReport (query: MultimetricTrendReportQuery): Promise<any> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/report-csv`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse;
  const report = response.ok ? response.blob(): response.json();
  if (!report) throw new Error('No report found')

  return report;
}

async function getMultivarTrendReport (query: MultivarTrendReportQuery): Promise<MultivarTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/multimetric-trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<MultivarTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getNpsBreakdownReport (query: NpsBreakdownReportQuery): Promise<NpsBreakdownReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/nps-breakdown`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<NpsBreakdownReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getCSATAttributesReport (query: CSATAttributesReportQuery): Promise<CSATAttributesReport> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };
  let URL = `${constants.API_URL}/analytics/csat-attributes`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<CSATAttributesReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')
  return report;
}

async function getDataListReport (options: GetDataListOptions): Promise<any[]> {
  const {
   query,
    pagination
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  let URL = `${constants.API_URL}/analytics/data-list`;
  if (pagination) {
    URL += Object.keys(pagination).reduce((urlParams, paramName, i) => {
      const separator = i === 0 ? '?' : '&'
      const paramValue = JSON.stringify(pagination[paramName as keyof PaginationOptions])

      return urlParams += `${separator}${paramName}=${paramValue}`
    }, '')
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(query),
  };
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse.json();
  return response.data;
}

async function getSummaryImportedRecords (conditions?: any): Promise<SummaryImportedRecords> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({collection: "summaryimportedrecords"}),
  };
  let URL = `${constants.API_URL}/analytics/generic-report`;
  let fullConditions = {};
  if (conditions) {
    fullConditions = {
      [conditions.dateRange.field]: {
        "$gte": conditions.dateRange.start,
        "$lte": conditions.dateRange.end
      }
    };
  }
  if (conditions.studyId) {
    fullConditions = { ...fullConditions, studyId: conditions.studyId }
  }
  if (conditions) {
    URL += `?conditions=${JSON.stringify(fullConditions)}&sort={"importedDate":1}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<SummaryImportedRecords> = await fetchResponse.json();
  return response.data;
}

async function getImportsTrendReport (query: KpiTrendReportQuery): Promise<KpiTrendReport> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/imports-trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}


export {
  getSegmentedReport,
  getKpiSummaryReport,
  getKpiTrendReport,
  getMultimetricTrendReport,
  getMultivarTrendReport,
  getNpsBreakdownReport,
  getDownloadReport,
  getCSATAttributesReport,
  getDataListReport,
  getSummaryImportedRecords,
  getImportsReport,
  getDownloadImportsCSV,
  getImportsTrendReport
}