import { useCallback } from 'react';
import { BaseKpiConfig, useBaseKpi } from './base/kpi-hook.base';
import { reports } from '../../services';
import { SegmentedReport, SegmentedReportQuery } from '../../services/reports';
import { messages } from '../../utils';

interface SegmentedReportConfig extends BaseKpiConfig {
  breakDownField: string;
  groups: Array<{
    distincId: string;
    groupCalc?: any;
    variables: Array<{
      name: string;
      calc: any;
    }>;
  }>;
}

const useSegmentedReport = (config: SegmentedReportConfig) => {
  const {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  } = useBaseKpi<SegmentedReport, SegmentedReportQuery>(config);

  const fetchData = async (query: SegmentedReportQuery): Promise<SegmentedReport> => {
    const response = await reports.getSegmentedReport(query);
    if (!response.breakDownDetail?.length || !response.groups?.length) {
      throw new Error(messages.NO_DATA_FOR_QUERY);
    }
    return response;
  };

  const buildSegmentedQuery = (): SegmentedReportQuery => {
    const baseQuery = buildBaseQuery();
    return {
      ...baseQuery,
      breakDownField: config.breakDownField,
      groups: config.groups
    } as SegmentedReportQuery;
  };

  const execRequest = useCallback(async () => {
    try {
      setAnalyticsResult(undefined);
      setAnalyticsError(undefined);
      setLoading(true);

      const validationError = validateRequest();
      if (validationError) {
        return setAnalyticsError(validationError);
      }

      const query = buildSegmentedQuery();
      const response = await fetchData(query);
      
      setAnalyticsResult(response);
    } catch (err) {
      setAnalyticsError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [studies.selected, dateRange, filters]);

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ] as const;
};

export default useSegmentedReport;