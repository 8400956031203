import { useCallback } from 'react';
import { BaseKpiConfig, useBaseKpi } from './base/kpi-hook.base';
import { reports } from '../../services';
import { NpsBreakdownReport, NpsBreakdownReportQuery } from '../../services/reports';
import { messages } from '../../utils';

interface NpsBreakdownConfig extends BaseKpiConfig {
  breakDownField: string;
  npsField: string;
}

const useNpsBreakdown = (config: NpsBreakdownConfig) => {
  const {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  } = useBaseKpi<NpsBreakdownReport, NpsBreakdownReportQuery>(config);

  const fetchData = async (query: NpsBreakdownReportQuery): Promise<NpsBreakdownReport> => {
    const response = await reports.getNpsBreakdownReport(query);
    if (response.length < 1) {
      throw new Error(messages.NO_DATA_FOR_QUERY);
    }
    return response;
  };

  const buildNpsQuery = (): NpsBreakdownReportQuery => {
    const baseQuery = buildBaseQuery();
    return {
      ...baseQuery,
      breakDownField: config.breakDownField,
      npsField: config.npsField
    } as NpsBreakdownReportQuery;
  };

  const execRequest = useCallback(async () => {
    try {
      setAnalyticsResult(undefined);
      setAnalyticsError(undefined);
      setLoading(true);

      const validationError = validateRequest();
      if (validationError) {
        return setAnalyticsError(validationError);
      }

      const query = buildNpsQuery();
      const response = await fetchData(query);
      
      setAnalyticsResult(response);
    } catch (err) {
      setAnalyticsError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [studies.selected, dateRange, filters]);

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ] as const;
};

export default useNpsBreakdown;
