import { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice } from '../../../store/slices/studies';
import { selectDateRange, selectFilters, selectCompare, selectCompareRange } from '../../../store/slices/filters';
import { Filter } from '../../../services/analytics';
import { DataProviderType } from '../../../services/views';

export interface BaseKpiConfig {
  dateRangeField: string;
  dataProvider?: DataProviderType;
  field?: string;
  calc?: any;
  baseFilters?: Filter[];
  compareWithPreviosPeriod?: boolean;
}

export function useBaseKpi<T, Q>(config: BaseKpiConfig) {
  const studies = useAppSelector(selectSlice);
  const filters = useAppSelector(selectFilters);
  const dateRange = useAppSelector(selectDateRange);
  const compareRange = useAppSelector(selectCompareRange);
  const compareCheck = useAppSelector(selectCompare);
  
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<T | undefined>();

  const validateRequest = () => {
    if (!studies.selected) {
      return new Error('No hay estudio seleccionado');
    }
    if (!dateRange.start || !dateRange.end) {
      return new Error('No hay periodo de tiempo seleccionado');
    }
    return undefined;
  };

  const buildBaseQuery = (): Partial<Q> => {
    const { dateRangeField, field, calc, baseFilters = [], compareWithPreviosPeriod } = config;
    
    const query: any = {
      studyId: studies.selected?._id,
      dateRange: {
        field: dateRangeField,
        start: dateRange.start?.startOf('day').toISOString(),
        end: dateRange.end?.endOf('day').toISOString(),
      },
    };
    if(field) query.field = field;
    if(calc) query.calc = calc;

    if (compareWithPreviosPeriod && compareCheck && compareRange.start && compareRange.end) {
      query.compareDateRange = {
        start: compareRange.start.startOf('day').toISOString(),
        end: compareRange.end.endOf('day').toISOString(),
      };
    }

    if (filters.length || baseFilters.length) {
      const standarizedFilters = filters.map(f => ({
        field: f.field,
        values: f.values.map(v => v.value),
      }));

      if (baseFilters.length) {
        const filtersToOverride = standarizedFilters.map(bf => bf.field);
        const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field));
        query.filters = [...newBaseFilters, ...standarizedFilters];
      } else {
        query.filters = standarizedFilters;
      }
    }

    return query;
  };

  return {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  };
}