import { useCallback } from 'react';
import { BaseKpiConfig, useBaseKpi } from './base/kpi-hook.base';
import { AdditionalField } from '../../services/comments';
import { GetDataListOptions } from '../../services/reports';
import { getDataListReport } from '../../services/reports';

interface UseDataListConfig extends BaseKpiConfig {
  mainField: string;
  valueMainField: string;
  additionalFields?: AdditionalField[];
  display?: string;
}

const useReportDataList = (config: UseDataListConfig) => {
  const {
    loading,
    analyticsError,
    analyticsResult,
    setLoading,
    setAnalyticsError,
    setAnalyticsResult,
    validateRequest,
    buildBaseQuery,
    studies,
    dateRange,
    filters
  } = useBaseKpi<Array<any>, GetDataListOptions>(config);

  const buildDataListQuery = (): GetDataListOptions => {
    const baseQuery = buildBaseQuery();
    return {
      query:{
      ...baseQuery,
      mainField: config.mainField,
      valueMainField: config.valueMainField,
      additionalFields: config.additionalFields,
      ...(config.display && { display: config.display }),
      },
      pagination: {
        sort: { createdAt: -1 }
      }
    } as GetDataListOptions;
  };

  const execRequest = useCallback(async () => {
    try {
      setAnalyticsResult(undefined);
      setAnalyticsError(undefined);
      setLoading(true);

      const validationError = validateRequest();
      if (validationError) {
        return setAnalyticsError(validationError);
      }

      const query = buildDataListQuery();
      console.log('query', query);
      const response = await getDataListReport(query as GetDataListOptions);
      
      setAnalyticsResult(response);
    } catch (err) {
      setAnalyticsError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [studies.selected, dateRange, filters]);

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ] as const;
};

export default useReportDataList;